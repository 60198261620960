import {
    Box,
    Button,
    Center,
    CloseButton,
    Container,
    Highlight,
    HStack,
    Icon,
    Image,
    Link,
    SimpleGrid,
    Spacer,
    Square,
    Stack,
    Text,
    useBreakpointValue,
    VStack,
} from "@chakra-ui/react"
import ResponsiveNavbar from "./components/ResponsiveNavbar"
import { BsLayoutSplit, BsViewStacked } from "react-icons/bs"
import { FaDiscord, FaTwitter } from "react-icons/fa"
import { MdGeneratingTokens } from "react-icons/md"
import { FiInfo } from "react-icons/fi"
import { CopyIcon } from "@chakra-ui/icons"
import { DIS_INV, IDE_URL, TWEET_URL } from "./constants"
import { Link as RouterLink } from "react-router-dom"

const UnderlineText = ({ text }: { text: string }) => {
    return (
        <Highlight query={text} styles={{ textDecoration: "underline", textDecorationColor: "brand.500" }}>
            {text}
        </Highlight>
    )
}

const App = () => {
    const isMobile = useBreakpointValue({ base: true, md: false })
    return (
        <Center w="full">
            <VStack>
                <VStack gap="7">
                    <Box py={{ base: "4", md: "2.5" }} px="2" w="full" position="relative" bg="#3b82f6" boxShadow="sm">
                        <Stack
                            direction={{ base: "column", sm: "row" }}
                            justify="space-between"
                            spacing={{ base: "3", md: "2" }}
                        >
                            <Stack
                                spacing="4"
                                direction={{ base: "column", md: "row" }}
                                align={{ base: "start", md: "center" }}
                            >
                                {!isMobile && (
                                    <Square size="12" bg="bg-subtle" borderRadius="md">
                                        <Icon as={FiInfo} boxSize="6" color='white'/>
                                    </Square>
                                )}
                                <Stack
                                    direction={{ base: "column", md: "row" }}
                                    spacing={{ base: "0.5", md: "1.5" }}
                                    pe={{ base: "4", sm: "0" }}
                                    alignItems={{ base: "start", md: "baseline" }}
                                >
                                    <Link href="https://trywale.com" color='white' fontWeight="medium" fontSize="lg">We have rebranded to Wale.</Link>
                                    <Link href="https://trywale.com" color='gray.200' fontSize="md">Checkout our new website!</Link>
                                </Stack>
                            </Stack>
                            <Stack
                                direction={{ base: "column", sm: "row" }}
                                spacing={{ base: "3", sm: "2" }}
                                align={{ base: "stretch", sm: "center" }}
                            >
                                <Button as={Link} href="https://trywale.com" width="full" colorScheme='gray' variant='solid'>
                                    Learn More
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                    <ResponsiveNavbar />
                    <VStack w="75%" gap="5" textAlign="center">
                        <Box>
                            <Text fontSize="5xl" fontWeight="semibold">
                                Welcome to your <UnderlineText text="Prompt" />
                            </Text>
                            <Text fontSize="5xl" fontWeight="semibold">
                                <UnderlineText text="Engineering" /> toolkit
                            </Text>
                        </Box>
                        <Text>Quickly develop, iterate upon, and test LLM prompts</Text>
                        <Button as="a" href={IDE_URL} colorScheme="brand" size="lg">
                            Try Dyno for Free
                        </Button>
                        <HStack>
                            <Button as="a" href={DIS_INV} colorScheme="discord" size="sm">
                                <Icon as={FaDiscord} color="disord.40" fontSize="2xl" marginRight="10px" />
                                Join our Discord
                            </Button>
                            <Button as="a" href={TWEET_URL} size="sm">
                                <Icon as={FaTwitter} color="brand.500" fontSize="2xl" marginRight="10px" />
                                Follow @trydyno
                            </Button>
                        </HStack>
                    </VStack>
                    <Box w="75%" position="relative">
                        <Image src="/ui.png" alt="Dyno UI" />
                        <Box
                            zIndex="-1"
                            position="absolute"
                            top="calc(-20%)"
                            w="100vw"
                            left="calc(-50vw + 50%)"
                            height="calc(100%)"
                            background="radial-gradient(50% 50% at center, transparent 10%, white 100%), url(/home-ui-bg.svg)"
                        />
                    </Box>
                    <VStack bg="blue.500" py="10%" px="10" w="full" textAlign="center" color="white" id="mission">
                        <Text fontWeight="semibold" fontSize="4xl">
                            We're in pursuit of the best Prompt Engineering experience
                        </Text>
                        <Text fontSize="xl">
                            Our goal is to provide a robust suite of tooling to make Prompt Engineering a painless
                            experience.
                        </Text>
                    </VStack>
                    <VStack w="full" spacing="10" py="10%" px="5%" id="features">
                        <Text
                            fontWeight="semibold"
                            fontSize="4xl"
                            mr={{ base: 1, lg: 2 }} /* mirror the grid spacing to make it equal */
                        >
                            Using Playground sucks. We fixed it.
                        </Text>
                        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="10">
                            <Stack>
                                <Icon as={BsLayoutSplit} color="brand.500" fontSize="5xl" />
                                <Text fontWeight="semibold">Dual Wield Prompts</Text>
                                <Text>
                                    Stop relying on multiple tabs to test different prompts at the same time. Create a
                                    split view to work on multiple prompts at once.
                                </Text>
                            </Stack>
                            <Stack>
                                <Icon as={MdGeneratingTokens} color="brand.500" fontSize="5xl" />
                                <Text fontWeight="semibold">Token Usage Insights</Text>
                                <Text>
                                    LLM usage costs real money. We'll help you better understand the cost of your
                                    prompts.
                                </Text>
                            </Stack>
                            <Stack>
                                <CopyIcon color="brand.500" fontSize="5xl" />
                                <Text fontWeight="semibold">Save Prompts for Later</Text>
                                <Text>
                                    Your notes app needs a break. Duplicate and save prompts for later while you work on
                                    others.
                                </Text>
                            </Stack>
                            <Stack>
                                <Icon as={BsViewStacked} color="brand.500" fontSize="5xl" />
                                <Text fontWeight="semibold">Generate Multiple Outputs</Text>
                                <Text>
                                    Life life on the edge with a temperature greater than zero. Better understand how
                                    your users' output will look over time.
                                </Text>
                            </Stack>
                        </SimpleGrid>
                    </VStack>
                    <VStack spacing="3" pb="10%" textAlign="center">
                        <Text fontWeight="semibold" fontSize="5xl">
                            What are you waiting for?
                        </Text>
                        <Button as="a" href={IDE_URL} colorScheme="brand" size="lg">
                            Try Dyno for Free
                        </Button>
                        <HStack>
                            <Button as="a" href={DIS_INV} colorScheme="discord" size="sm">
                                <Icon as={FaDiscord} color="discord.40" fontSize="3xl" marginRight="10px" />
                                Join our Discord
                            </Button>
                            <Button as="a" href={TWEET_URL} size="sm">
                                <Icon as={FaTwitter} color="brand.500" fontSize="2xl" marginRight="10px" />
                                Follow @trydyno
                            </Button>
                        </HStack>
                    </VStack>
                    <HStack bg="blue.500" w="full" color="white" p="5" fontWeight="medium">
                        <Text>&#169; {new Date().getFullYear()} trydyno.com</Text>
                        <Spacer />
                        <Link as={RouterLink} to={"/privacy"}>
                            Privacy Policy
                        </Link>
                        <Link href="https://embed.trydyno.com">Embed</Link>
                        <Link href="mailto:contact@trydyno.com">Contact Us</Link>
                    </HStack>
                </VStack>
            </VStack>
        </Center>
    )
}

export default App
