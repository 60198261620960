import "inter-ui/inter.css"
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import theme from './theme';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { PrivacyPage } from "./PrivacyPage";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy",
    element: <PrivacyPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
// Add React Router to the app

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      {/* React Router */}
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);
